import {datadogRum} from '@datadog/browser-rum';
import {Context} from '@opentelemetry/api';
import {getWebAutoInstrumentations} from '@opentelemetry/auto-instrumentations-web';
import {ZoneContextManager} from '@opentelemetry/context-zone';
import {OTLPTraceExporter} from '@opentelemetry/exporter-trace-otlp-http';
import {registerInstrumentations} from '@opentelemetry/instrumentation';
import {Resource} from '@opentelemetry/resources';
import {BatchSpanProcessor, Span, WebTracerProvider} from '@opentelemetry/sdk-trace-web';
import {SEMRESATTRS_SERVICE_NAME, SEMRESATTRS_SERVICE_VERSION} from '@opentelemetry/semantic-conventions';
import {ORIGIN, VERSION} from 'shared/platform/config';

class CustomSpanProcessor extends BatchSpanProcessor {
  onStart(span: Span, parentContext: Context) {
    super.onStart(span, parentContext);

    const ddContext = datadogRum.getInternalContext();

    if (ddContext) {
      span.setAttribute('_bc.session.id', ddContext.session_id ?? '');
      span.setAttribute('_bc.view.id', ddContext.view?.id ?? '');
      span.setAttribute('_bc.view.name', ddContext.view?.name);
      span.setAttribute('_bc.view.url', ddContext.view?.url);
    }
  }
}

export const initOtel = () => {
  const provider = new WebTracerProvider({
    resource: new Resource({
      [SEMRESATTRS_SERVICE_NAME]: 'frontend',
      [SEMRESATTRS_SERVICE_VERSION]: VERSION,
    }),
  });

  // We're passing in `headers: {}` because it makes this use XHR instead of Beacon, and there's a problem with Beacon.
  // See more here: https://github.com/open-telemetry/opentelemetry-js/issues/3489
  provider.addSpanProcessor(
    new CustomSpanProcessor(new OTLPTraceExporter({url: `${ORIGIN}/api/traces`, headers: {}}), {
      scheduledDelayMillis: 30000,
    }),
  );

  provider.register({
    contextManager: new ZoneContextManager(),
  });

  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-fetch': {ignoreUrls: [/^https:\/\/browser-intake-us5-datadoghq\.com\//]},
        '@opentelemetry/instrumentation-xml-http-request': {
          ignoreUrls: [/^https:\/\/browser-intake-us5-datadoghq\.com\//],
        },
      }),
    ],
  });
};
